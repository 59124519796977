.drivers-container {
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 15px #cccccc, 0px 0px 0px #cccccc;
  flex-grow: 1;
}

.select {
  width: 100%;
  max-width: 300px;
}