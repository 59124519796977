.toasts-container {
  position: fixed;
  bottom: 0;
  z-index: 2222;
  padding: 1px;
}

.toasts-container .bottom-left {
    bottom: 10px;
    left: 20px;
}

/* .toasts-container.bottom-left > * {
  margin-left: -60px;
} */
