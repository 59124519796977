.full-loader-container-enter {
  opacity: 0;
}

.full-loader-container-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.full-loader-container-exit {
  opacity: 1;
}
.full-loader-container-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.full-loader {
  display: flex;
  flex-direction: row;

  flex-grow: 1;

  justify-content: center;
  align-items: center;
}

.full-loader > .full-loader-image {
  max-width: 200px;
}