.btn-loader {
  width: 110px;
}

.btn.btn-danger {
  background-color: #DC3545;
  color: #FFFFFF;
}

.btn-parent, .ui.label.btn-parent {
  background-color: rgb(239,213,213);
  /* color: #ffffff; */
}

.btn-child, .ui.label.btn-child {
  background-color: rgb(211,235,240);
  /* color: #ffffff; */
}

.btn.labeled {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.btn.labeled .label {
  position: absolute;
  top: -1px;
  left: -347px;
  height: 34px;
  display: none;
  z-index: 99999999999;
  border: 1px solid #122C49;
  border-radius: 5px 0 0 5px;

  min-width: 350px;
}

.btn:hover .label:not(:hover) {
  display: block;
}

/* List box */

.ui.dropdown {
  padding: 0.6rem 1.5rem;

  border: 1px solid #e5e5e5;
  background-color: #ffffff;

  border-radius: 5px;

  cursor: pointer;

  font-weight: bold;

  color: #122C49;
}

.ui.dropdown:hover, .ui.selection.dropdown:hover {
  border: 1px solid #122C49;
}

.ui.selection.active.dropdown,
.ui.selection.active.dropdown:hover,
.ui.selection.active.dropdown .menu,
.ui.selection.active.dropdown:hover .menu {
  border-color: #122C49
}

.ui.dropdown .menu.right-menu {
  right: 0;
  left: auto;
}

.ui.dropdown .menu .item {
  font-size: 13px;
}

.ui.dropdown .menu .item.red .text {
  color: #ff0000;
}
