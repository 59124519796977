#navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 auto;
  overflow-y: auto;

  background-color: #052643;

  flex-grow: 1;
  padding: 0 30px 15px 30px;
}

.navigationList {
  max-height: 100%;
  height: fit-content;
  overflow: auto;
}

.navigationList .item {
  width: 100%;

  line-height: 3rem;
  transition: all 0.2s;
}

.navigationList .item:first-child {
  margin-top: 1rem;
  border-top: 1px solid rgba(0, 83, 121, 0.5);
}

.navigationList .item {
  border-bottom: 1px solid #005379;
  width: 100%;
}

.navigationList .item .itemButton:not(.active):hover {
  background-color: #003E7F;
}

.navigationList .item .itemLink {
  width: 100%;
  color: white;
}

.navigationList .item svg {
  max-width: 1.7rem;
}

.navigationList .item .active, .navigationList .item .active svg{
  color: #00ffff;
}

.collapse-button {
  display: flex;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.4);
  transition: all 0.1s ease;
}

.collapse-button:hover {
  color: rgba(255, 255, 255, 0.6);
}

.navigationIcon, .collapse-button svg {
  margin-right: 15px;
}

/* Short Menu */

#sidebar.short-menu #navigation {
  padding: 0 0 30px 0;
}

#sidebar.short-menu .longItem {
  display: none;
}

#sidebar.short-menu .navigationList .item {
  padding: 0 0;
  justify-content: center;
}

#sidebar.short-menu .navigationList .item:first-child {
  margin-top: 0;
}

#sidebar.short-menu .navigationList .item svg {
  margin: auto;
}

#sidebar.short-menu .collapse-button {
  width: fit-content;
  margin: 0 auto;
}

#sidebar.short-menu .collapse-button svg {
  margin: 0;
}
