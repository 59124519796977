#logo {
  padding: 1rem 1rem;

  background-color: #052643;
  color: #FFFFFF;

  text-align: center;

  font-size: 1.5em;
  font-weight: bold;
}

#logo > img {
  max-width: 100%;
}

#logo .logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  gap: 5px;
}

#logo .headTitle {
  padding-bottom: 0.8rem;
}

/* Short Menu */

#sidebar.short-menu #logo {
  font-size: 20px;
  padding: 1.3rem 0.3rem 0.3rem 0.3rem;
}

#sidebar.short-menu .headTitle, #sidebar.short-menu .logoContainer img:last-child, #sidebar.short-menu .versionId  {
  display: none;
}

#sidebar .versionId {
  color: black;
  font-size: 12px;
}

#sidebar .versionId a {
  color: #0BFFFF
}
