.error_page {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: stretch;

  flex-grow: 1;

  padding-top: 3rem;
}

.error_page > div {
  font-weight: bold;
  flex-grow: 1;

  text-align: center;

  font-size: 4rem;
}