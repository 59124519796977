/* modal */

.modal-overlay {
  position: fixed;

  display: flex;

  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 2000;

  max-height: 100%;

  background-color: rgba(0,0,0, 0.5);
}

.modal {
  display: flex;
  flex-direction: column;

  overflow-y: scroll;

  width: 60%;
  height: 85%;

  min-width: 1100px;
  max-height: 100%;

  border-radius: 10px;

  color: #122C49;
  background-color: #ffffff;
}

.modal.modal-large {
  width: 80%;
}

.modal.modal-xlarge {
  width: 95%;
  height: 95%;
}

.modal > .modal-header {
  display: flex;
  flex-direction: row;

  align-items: center;

  border-bottom: 1px solid #dddddd;

  padding: 1rem 3rem;

  background-color: #F9F9F9;
}

.modal > .modal-header > :not(:last-child) {
  margin-right: 3rem;
}

.modal > .modal-header > .modal-title {
  flex-grow: 1;

  line-height: 36px;

  font-weight: bold;
}

.modal > .modal-header > .modal-close {
  cursor: pointer;
}

.modal > .modal-header > .modal-close:hover > * {
  color: #122C49;
}

.modal > .modal-content {
  flex-grow: 1;

  margin: 1.5rem 3rem;
}

.modal > .modal-content-fullwidth {
  flex-grow: 1;

  margin: 1.5rem 0rem;
}

.modal > .modal-footer {
  display: flex;
  flex-direction: row;

  justify-content: center;

  padding: 2rem;
}

.modal > .modal-content.flex-view, .modal > .modal-footer.flex-view {
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
}

.modal > .modal-content.flex-view .modal-row, .modal > .modal-footer.flex-view .modal-row {
  display: flex;
  flex-direction: row;

  justify-content: center;
}

.modal > .modal-content.flex-view .modal-row.justify-evenly {
  justify-content: space-evenly;
}

.modal > .modal-content.flex-view > .modal-row > *, .modal > .modal-footer.flex-view > .modal-row > *, .flex-view.space-children > .modal-row > * {
  margin-left: 1rem;
  margin-right: 1rem;
}

* > .modal-row:not(:last-child) {
  margin-bottom: 1.5rem;
}

.modal .ui.message {
  width: 100%;
  text-align: center;
}

.modal .modal-content.flex-view .modal-row > .modal-column, .modal .modal-footer.flex-view .modal-row > .modal-column {
   /* Trick to split equally the space taken by these children*/
  flex-basis: 0%;

  flex-grow: 1;
}

.modal .modal-content.flex-view .modal-column, .modal .modal-footer.flex-view .modal-column {
  display: flex;
  flex-direction: column;

  justify-content: center;
}

.modal .modal-content.flex-view .modal-column.justify-start, .modal .modal-footer.flex-view .modal-column.justify-start {
  justify-content: flex-start;
}

.modal .modal-content.flex-view .modal-column.align-center, .modal .modal-footer.flex-view .modal-column.align-center {
  align-items: center;
}

.modal .modal-content.flex-view .modal-column.no-flex-grow, .modal .modal-footer.flex-view .modal-column.no-flex-grow {
  flex-grow: 0;
}

.modal > .modal-content.flex-view .images-cell {
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.channel-logo.channel-logo-edit {
  margin-top: 1rem;
  position: relative;
}

.channel-logo.channel-logo-edit > .btn {
  position: absolute;
  right: 0;
  bottom: -10px;
}

.channel-logo.channel-logo-edit > input {
  display: none;
}
