.familyLineup {
  position: relative;

  width: 21px;
  height: 75px;

  padding: 0.3rem;
  text-align: center;

  cursor: default;

  letter-spacing: -0.3em;
  font-size: 0.6rem;
  font-weight: bold;
  text-transform: uppercase;
  writing-mode: vertical-rl;
  text-orientation: upright;

  border-radius: 5px 0 0 5px;
  /* transform: rotate(-180deg); */
}

.familyLineup + .familyLineup {
  border-radius: 0;
}

.familyLineup.parentLineup {
  background-color: #ad2f2f;
  color: white;
}

.familyLineup.parentLineupLight {
  background-color: rgba(173, 47, 47, 0.6);
  color: white;
}

.familyLineup.inheritedLineupLight {
  letter-spacing: -0.5em;
  /* font-size: 0.5rem; */
  padding: 0.1rem 0.3rem;
  background-color: rgba(34, 157, 181, 1);
  color: white;
}

.familyLineup.childLineup {
  background-color: rgba(34, 157, 181, 1);
  color: white;
}

.familyLineup.orhpanLineup {
  background-color: rgba(84, 179, 233, 0.1);
  color: white;
}

.familyLineup.overrideLineup {
  letter-spacing: -2px;
  font-size: 0.5rem;
  background-color: rgba(22, 166, 79, 0.9);
  color: white;
}

.familyLineup.overrideLineup.overrideLineup--square {
  border-radius: 0;
}

.child-row-parent {
  background-color: rgba(173, 47, 47, 0.06);
}

.familyLineup .lsinfo {
  position: absolute;
  top: 0px;
  left: 16px;

  width: 300px;
  height: 76px;

  display: none;
  flex-direction: column;
  justify-content: space-between;

  padding: 10px;

  z-index: 2;

  text-transform: initial;
  letter-spacing: initial;
  writing-mode: horizontal-tb;
  font-size: 0.8rem;
}

.familyLineup:hover .lsinfo {
  display: flex;
}

.familyLineup .parentInfo {
  background-color: #ad2f2f;
}

.familyLineup .inheritInfo {
  background-color: rgba(34, 157, 181, 1);
}

.familyLineup .overrideInfo {
  background-color: rgba(22, 166, 79, 1);
}
