.driver-container {
  padding: 25px;
  margin-bottom: 1em;
  justify-content: space-around;
  border-radius: 7px;
  box-shadow: 0px 2px 12px -4px rgba(0,0,0,0.1), 0px 3px 18px 0px rgba(0,0,0,0.1);
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.mr-3 {
  margin-right: 3rem;
}

.my-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.pl-1 {
  padding-left: 1rem;
}

.pr-1 {
  padding-right: 1rem;
}

.mt-1-5 {
  margin-top: 1.5rem;
}

.flex-grow-equal {
  flex-grow: 1;
  flex-basis: 0;
}

.driver-title {
  margin: 0.5rem 0 0.5rem 0.5rem;
  font-size: 1.3rem;
  font-weight: bold;
}

.status-enabled {
  color: lightgreen;
}

.status-disabled {
  color: red;
}

.flex-between {
  justify-content: space-between;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-align-end {
  align-items: flex-end;
  display: flex;
}
