
.tabs {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.tabs > .tabs-navbar {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 1em;
  gap: 1em;
  flex-grow: 1;
  font-size: 0.9rem;
}
.tabs > .tabs-navbar.tabs-navbar-small {
  font-size: 0.875em;
}

.tabs > .tabs-navbar > .tab-nav {
  padding: 0.75em;
  margin: 0;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
}

.tabs > .tabs-navbar > .tab-nav a {
  color: #092C4B;
}

.tabs > .tabs-navbar > .tab-nav.active {
  border-bottom: 4px solid #00ffff;
}

.tabs > .tabs-navbar > .tab-nav > .tab-nav-number {
  font-weight: normal;
  padding-left: 0.3em;
}

.tabs > .tabs-navbar-title-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.4rem 0.3rem;
}

.tabs > .tabs-navbar-title-section > .tabs-navbar-title {
  font-weight: bold;
  font-size: 1.2rem;
}

.tabs > .tabs-optional-actions {
  padding: 0.4rem 0.3rem;
}

.tabs > .tabs-optional-actions > .btn {
  padding: 0.7rem 1.5rem;
}

.tabs > .tabs-optional-actions > .btn:not(:last-child) {
  margin-right: 1rem;
}
