html,
body {
  height: 100%;

  overflow-y: hidden;

  margin: 0;

  color: #092C4B;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 13px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}

#root {
  display: flex;
  height: 100%;
  overflow: hidden;

  color: #092C4B;
}

.flex-grow {
  flex-grow: 1;
}

.flex-center {
  align-items: center;
}

.cursor-default {
  cursor: default;
}

.justify-evenly {
  justify-content: space-evenly;
}

.pointer {
  cursor: pointer;
}

.fa-icon {
  margin-right: 1rem;
  vertical-align: middle;
}

.blue-icon {
  color: #092C4B;
}

.grey-icon {
  color: #aaabac;
}

.red-icon {
  color: #ff0000;
}

.orange-icon {
  color: #fc9463;
}

.green-icon {
  color: #00ff00;
}

.copyright {
  opacity: 0.8;
}

.grey {
  color: #888888;
}

.green, .green > .text {
  color: #006600;
}

.orange, .orange > .text {
	color: #fc9463;
}

.red, .red > .text {
  color: #AB0000;
}

.center {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.__json-string__ { color: #83ba52; }
.__json-number__ { color: #fc9463; }
.__json-value__ { color: #fc9463; }
.__json-boolean__ { color: #72e0d1; }
.__json-null__ { color: red; }
.__json-key__ { color: #f5d67b; }
.__json-pretty__ {
  padding: 0.7rem;
  background-color: #001628;
  color: #FFFFFF;
  border-radius: 6px;
}

.background-red {
  background-color: #FF0000;
}

.fs10 {
  font-size: 10px;
}

.fs20 {
  font-size: 20px;
}

/* margin */

.mt1 {
  margin-top: 1rem;
}

.mt2 {
  margin-top: 2rem;
}

.mb1 {
  margin-bottom: 1rem;
}

.mb2 {
  margin-bottom: 2rem;
}

.mr1 {
  margin-right: 1rem;
}
.mr2 {
  margin-right: 2rem;
}

.ml1 {
  margin-left: 1rem;
}

.ml05 {
  margin-left: 0.5rem;
}

.RSPBprogressBar {
  width: 100%;
}

.indexedStep {
  color: white;
  width: 20px;
  height: 20px;
  font-size: 12px;
  background-color: rgba(211, 211, 211, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indexedStep.accomplished {
  background-color: #092C4B;
}

.not-ready { 
  background-color: #dddddd !important;
  transform: none !important;
  cursor: default !important;
}

.btn.not-ready:hover {
  border: 1px solid #dddddd !important;
}

.border-top {
  border-top: 1px solid #eeeeee;
}

.border-bottom {
  border-bottom: 1px solid #eeeeee;
}

.little-side-border {
  margin-left: 1rem;
  margin-right: 1rem;
}

/* list */

ul.list, ul.list > li {
  list-style: circle;
}

ul.list > li:not(:first-child) {
  margin-top: 1rem;
}

/* flexbox */

.row {
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;
}

.column {
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
}

.flex-equal-row-size {
  flex-basis: 0;
}

.baseline {
  align-items: baseline;
}

.page-content {
  flex: 1;
  padding: 2em;
}

.channel-logo {
  display: flex;
  align-items: center;

  margin-top: 1rem;

  height: 100px;
  width: 100px;
  box-shadow: 0 0 4px #bbbbbb;
  background-color: #dddddd;
}

.channel-logo > button > label {
  cursor: pointer;
}

.channel-logo > img {
  max-width: 100px;
  max-height: 100px;
}

.text-tips {
  font-size: 11px;
  color: #111111;
}

/* Tag */

.tag {
  display: flex;
  flex-direction: row;

  align-items: center;

  padding: 0.2rem 0.8rem 0.2rem 0.5rem;

  /* margin-top: 0.5rem; */

  line-height: 16px;

  border-radius: 1rem;
  box-shadow: 0 0 4px #bbbbbb;

  font-size: 13px;
  font-weight: bold;

  cursor: pointer;

  border: 1px solid #ffffff;
}

.tag:hover {
  border: 1px solid #e5e5e5;
}

.tag:not(:last-child) {
  margin-right: 1rem;
}

.tag .tag-cross {
  display: inline-block;

  font-size: 15px;

  border-radius: 10px;
  transform: rotate(45deg);

  margin-right: 5px;

  background-color: #122C49;
  color: #ffffff;
}

/* btn / input & group */

.action-group {
  display: flex;
  flex-direction: row;

  flex-wrap: wrap;
}

.action-group:last-child {
  flex-grow: 1;
}

.action-group:not(:last-child) {
  margin-right: 2rem;
}

.btn {
  padding: 0.6rem 1.5rem;
  border: 1px solid #e5e5e5;
  background-color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  color: #122C49;
  font-size: 0.875em;
  font-weight: bold;
  font-family: inherit;
  border-radius: 7px;
  padding: 0.6em 1.25em;
  transition: all 0.16s ease;
}

.btn:hover {
  border: 1px solid #122C49;
  background-color: rgba(0, 59, 96, 0.04);
}

.btn.btn-no-background {
  background: none;
}

.btn.btn-text {
  padding: 0;
}

.btn.btn-primary {
  background-color: #00ffff;
  border-color: #00ffff;
}

.btn.btn-primary:hover {
  background-color: rgb(34, 177, 177);
  border-color: rgb(34, 177, 177);
}

.btn.btn-warning {
  background-color: #FCBB43;
  border-color: #FCBB43;
}

.btn:active {
  transform: translateY(2px);
}

.btn.btn-square-padding {
  padding: 0.6rem 1rem;
}

.btn.btn-nude {
  border: 0;
}

.btn.btn-nude.not-ready {
  border: 1px solid #e5e5e5;
}

.btn-no-hover {
  pointer-events: none;
}

.btn.btn-disabled {
  pointer-events: none;
  background-color: #dddddd;
}

.btn.btn-medium {
  padding: 0.6rem 2.5rem;
}

.btn.btn-large {
  padding: 0.6rem 5rem;
}

.btn.btn-round {
  padding: 0.5rem 0.6rem;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0 0 4px #bbbbbb;
}

.input {
  padding: 0.5rem 1rem;

  border: 1px solid #e5e5e5;

  flex-grow: 1;

  color: #122C49;
}

.input:hover {
  border: 1px solid #122C49;
}

.input-form {
  border: none;

  padding: 0;

  flex-grow: 1;

  font-weight: bold;
  color: #122C49;
  width: 100%;
}

.input-form::placeholder {
  font-style: italic;
  font-weight: bold;

  color: #91A7BA;

  font-size: 11px;
}

.input-border {
  border: 1px solid #67798B;
  padding: 0.5rem;
  border-radius: 10px;
}


/* select */

.select {
  padding: 0.6rem 2.5rem 0.6rem 1rem;

  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;

  font-weight: bold;

  color: #122C49;

  /* -webkit-appearance: none;
  appearance: none;
  outline: none; */

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background-position: right 1rem bottom 0.6rem;
  background-repeat: no-repeat;
  background-size: 1rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
}

.descriptor-field > .select {
  border: 0;
}

.descriptor-field > .select:hover {
  cursor: pointer;
  border: 0;
}

.select:hover {
  border: 1px solid #122C49;
}

.select::-ms-expand {
  display: none;
}

/* buttons group */

.action-group > :not(:only-child) {
  border-radius: 0;
}

.action-group > :first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.action-group > :not(:last-child):not(:hover) {
  border-right: none;
}

.action-group > :last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.action-group > *:hover + * {
  border-left: none;
}

/* Descriptor fields */

.descriptor-field {
  display: flex;
  flex-direction: column;

  flex-grow: 1;

  border-bottom: 1px solid #bbbbbb;
}

.descriptor-field.no-border {
  border: none;
}

.descriptor-field > .field-name {
  color: #67798B;

  font-size: 10px;

  font-weight: bold;
}

.descriptor-field > .field-value {
  padding-top: 0.3rem;
  padding-bottom: 0.7rem;

  font-size: 12px;

  font-weight: bold;
}

.descriptor-field > .field-error {
  padding-top: 0.3rem;
  padding-bottom: 0.7rem;

  color: #ff0000;
}

.item-logo {
  display: flex;
  align-items: center;

  height: 50px;
  width: 50px;
  border-radius: 6px;
  overflow: hidden;
  background-color: #f5f5f5;
}

.item-logo > img {
  max-width: 50px;
  max-height: 50px;
}
