* > .modal-row:not(:last-child).little-margin-bottom-cg {
  margin-bottom: 0.2rem;
}

.cg-with-hint {
  position: relative;
}

.cg-with-hint > svg[role="img"]:hover + .cg-hint {
  display: block;
}

.cg-hint {
  display: none;
  position: absolute;
  
  top: -30px;

  width: 230px;
  
  padding: 0.5rem;

  text-align: center;
  background-color: #ccc;
}

.cg-hint-top {
  top: -50px;
  width: 600px;
}